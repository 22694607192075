import React from "react";

export const AssetTransfersConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/asset-management/asset-transfers",
      component: React.lazy(() => import("./AssetTransfers")),
    },
    {
      path: "/asset-management/asset-transfer/:productId",
      component: React.lazy(() => import("./AssetTransferRecord")),
    },
  ],
};
