import { FileManagerAppConfig } from "./file-manager/FileManagerAppConfig";
import { CalendarAppConfig } from "./calendar/CalendarAppConfig";
import { ChatAppConfig } from "./chat/ChatAppConfig";
import { ECommerceAppConfig } from "./e-commerce/ECommerceAppConfig";
import { ScrumboardAppConfig } from "./scrumboard/ScrumboardAppConfig";
import { WeatherDataConfig } from "./weather/weatherDataConfig";
import { InventoryItemsKanbanConfig } from "./InventoryItems/InventoryItemsKanbanConfig";
import { PersonnelAppConfig } from "./Personnel/PersonnelAppConfig";
import { UserSettingsConfig } from "./UserSettings/UserSettingsConfig";
import { WorkRequestsConfig } from "./WorkRequest/WorkRequestConfig";
import { ComingSoonPageConfig } from "./pages/ComingSoon/ComingSoonPageConfig";
import { Error404PageConfig } from "./pages/NotFound/Error404PageConfig";
import { CrewsConfig } from "./crews/CrewsConfig";
import { ClassificationsConfig } from "./classificationSchems/ClassificationsConfig";
import { AssetsConfig } from "./Assets/AssetsConfig";
import { AssetTransfersConfig } from "./AssetTransfers/AssetTransfersConfig";
import { StatusUpdatesConfig } from "./StatusUpdates/StatusUpdatesConfig";
import { MetersConfig } from "./Meters/MetersConfig";
import { FleetAnalysisConfig } from "../FleetAnalysis/FleetAnalysisConfig";
import { TaskGroupsConfig } from "../apps/TaskGroups/TaskGroupsConfig";

export const appsConfigs = [
  FleetAnalysisConfig,
  FileManagerAppConfig,
  CalendarAppConfig,
  ChatAppConfig,
  ECommerceAppConfig,
  ScrumboardAppConfig,
  WeatherDataConfig,
  InventoryItemsKanbanConfig,
  PersonnelAppConfig,
  WorkRequestsConfig,
  UserSettingsConfig,
  ComingSoonPageConfig,
  Error404PageConfig,
  CrewsConfig,
  ClassificationsConfig,
  AssetsConfig,
  AssetTransfersConfig,
  MetersConfig,
  TaskGroupsConfig,
  StatusUpdatesConfig,
];
