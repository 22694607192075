import React from "react";

export const StatusUpdatesConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/asset-management/status-updates",
      component: React.lazy(() => import("./StatusUpdates")),
    },
    {
      path: "/asset-management/status-update/:statusId",
      component: React.lazy(() => import("./StatusUpdates")),
    },
  ],
};
