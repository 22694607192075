import React from "react";

export const AssetsConfig = {
  settings: {
    layout: {
      config: {}
    }
  },
  routes: [
    {
      path: "/asset-management/assets",
      component: React.lazy(() => import("./Assets"))
    },
    {
      path: "/asset-management/asset/:productId",
      component: React.lazy(() => import("./AssetRecord"))
    },
    {
        path: "/asset-management/orphan-list",
        component: React.lazy(() => import("../../Assets/orphan-list/OrphanList"))
    },
    {
        path: "/asset-management/transfers",
        component: React.lazy(() => import("../../Assets/asset-transfer/Transfers"))
    },
  ]
};
